import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/Layout';
import Hero from '../components/Screens/Hero';

// ====

const Four0Four = (props) => {
  const { data } = props;

  let doc;
  if (data) {
    doc = JSON.parse(data.fourfour.content);
  }

  return (
    <Layout meta={doc.meta}>
      <Hero title="404" copy={doc.fourohfour} />
    </Layout>
  );
};

export default Four0Four;

// ====

export const QUERY = graphql`
  query Four0FourDefaultQuery {
    fourfour: storyblokEntry(
      slug: { eq: "settings" }
      lang: { eq: "default" }
    ) {
      content
    }
  }
`;
